body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.order-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
}

.align-text-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.link {
  color: #1e40af;
  text-decoration: none;
}

.link:hover {
  color: #60a5fa;
  cursor: pointer;
}
