.managerContainer {
    padding: 10px;
    display: block;
}

.SectionContainerBig {
    margin: 20px;
    padding: 20px;
    background-color: #fefefe;
    flex: 1;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 1px 10px #b4c1d599;
}

.SectionContainerBig .Header h2,
.SectionContainerBig .Header i,
.SectionContainerBig .Header button {
    margin-left: 20px;
}

.SectionContainerBig .Header i {
    font-size: 0.8rem;
    opacity: 0.6;
    font-weight: 600;
    float: right;
}

.SectionContainerBig .Header h2 {
    font-size: 3rem;
}

.SectionContainerBig .Body {
    padding: 20px;
}

.FormContainer {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.FormContainer .FormRaw .FormField {
    display: inline-block;
    width: 33%;
    margin: auto;
}

.FormContainer .FormRaw .FormField label {
    width: 15%;
}

.FormContainer .FormRaw .FormField input {
    width: 75%;
}

/*     margin: 20px;
        background-color: #fefefe;
        flex:1;
        flex-direction:raw;
         border-radius: 10px;
    box-shadow: 0px 1px 10px #b4c1d599;
 */

.managerHeader {
    display: block;
}

.managerHeader div {
    display: inline-block;
    margin: 5px 20px;
}

label {
    margin-right: 10px;
}

.AlertRed {
    background-color: #ff111122;
    border: 2px solid #ff1111aa;
    padding: 10px 20px;
    border-radius: 10px;
}

.AlertRed .IconBig {
    font-size: 1.9rem;
    margin-right: 10px;
    color: #ff1111aa;
}

.searchBtn {
    margin-left: 20px;
    padding: 5px 20px;
}

.newKeys {
    margin-left: 50px;
    padding: 5px 20px;
    background-color: #fefefe;
    border: 1px solid #f7921c !important;
    color: #f7921c !important;
}

.CatInput {
    background-color: #f7921c22;
    border: none;
    color: #f7921c;
    padding: 5px;
    text-align: center;
    display: block;
    border-radius: 5px;
    border-bottom: 2px solid #f7921c;
}

.SmallBtn {
    background-color: #f7921c;
    border: "none";
    font-size: "1.5rem";
    padding: 5px 10px;
    border: none;
    margin: 5px;
    border-radius: 5px;
}

.productPreview {
    margin: 20px;
    padding: 20px;
    background-color: #fefefe;
    display: inline-block;
    width: 29%;
    border-radius: 10px;
    box-shadow: 0px 1px 10px #b4c1d599;
    text-align: center;
}

.productPreview img {
    width: 90%;
}
